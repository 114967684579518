<template>
  <div class="dashboard">
    <k-dialog
      v-if="visibleDialog"
      :title="'Please confirm'"
      @close="toggleDialog"
    >
      <p :style="{ margin: '25px', textAlign: 'center' }">
        Are you sure you want to continue?
      </p>
      <dialog-actions-bar>
        <button class="k-button" @click="toggleDialog">No</button>
        <button class="k-button" @click="toggleSubmit">Yes</button>
      </dialog-actions-bar>
    </k-dialog>
    <k-dialog v-if="visibleSubmit" @close="toggleSubmit">
      <p :style="{ margin: '25px', textAlign: 'center' }">
        Your request has been submitted
        <font-awesome-icon size="2x" :icon="['far', 'check-circle']" />
      </p>
      <dialog-actions-bar>
        <button class="k-button" @click="toggleSubmit">ok</button>
      </dialog-actions-bar>
    </k-dialog>
    <h1 class="header-color" style="text-align: left; padding: 10px">
      <font-awesome-icon
        size="1x"
        class="header-color"
        :icon="['fas', 'search-dollar']"
      />
      Purchase Bonds
    </h1>

    <div class="government">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-card class="dashboard-card">
              <template #header>
                <div class="float-right">
                  <span class="ml-1"
                    ><k-button @click="toggleDialog"
                      ><b>Request Bond Purchase</b></k-button
                    ></span
                  >
                </div>
              </template>
              <div class="container-fluid">
                <p>
                  <b> <h4>InfraPX Bonds</h4></b>
                </p>
                <div class="row flex-row flex-nowrap">
                  <Grid
                    ref="purchaseBond"
                    :style="{ height: '900px', width: '100%' }"
                    :data-items="gridData"
                    :selected-field="selectedField"
                    :columns="columns"
                    :sortable="sortable"
                    :filterable="filterable"
                    :groupable="groupable"
                    :reorderable="reorderable"
                    :pageable="{ buttonCount: 4, pageSizes: true }"
                    :skip="skip"
                    :take="take"
                    :sort="sort"
                    :group="group"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    @selectionchange="onSelectionChange"
                    @headerselectionchange="onHeaderSelectionChange"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    :scrollable="'virtual'"
                    @cellclick="onRowClick"
                  >
                  </Grid>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { Grid } from "@progress/kendo-vue-grid";
import { process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-buttons-vue-wrapper";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
export default {
  name: "purchaseBonds",
  components: {
    Grid: Grid,
    "k-button": Button,
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
  },
  computed: {
    areAllSelected() {
      return this.items.findIndex((item) => item.selected === false) === -1;
    },

    selectedItem() {
      if (this.items)
        return this.items.find((item) => item.bond_id === this.selectedID);
      else {
        return null;
      }
    },
    columns() {
      return [
        {
          field: "selected",
          width: "50px",
          headerSelectionValue: this.areAllSelected,
          filter: "none",
        },
        ...this.staticColumns,
      ];
    },
  },
  data: function () {
    return {
      expandedItems: [],
      visibleDialog: false,
      visibleSubmit: false,
      items: this.$store.state.bondData
        .filter((item) => item.status.toUpperCase() === "PLANNED")
        .map((item) => {
          return { ...item, selected: false };
        }),
      gridData: [],
      sortable: true,
      filterable: true,
      groupable: true,
      reorderable: true,
      skip: 0,
      take: 20,
      sort: [{ field: "asset_class", dir: "desc" }],
      group: [{ field: "asset_class" }],
      filter: null,
      selectedField: "selected",
      selectedID: 1,
      staticColumns: [
        { field: "owner", title: "Municipality", width: "250px" },
        { field: "status", title: "Status", width: "200px" },
        { field: "asset_id", title: "Asset ID", width: "250px" },
        { field: "asset_class", title: "Asset Class", width: "250px" },
        { field: "descriptor", title: "Project", width: "250px" },
        {
          field: "amount",
          title: "Amount (CAD)",
          filter: "numeric",
          width: "205px",
          cell: this.amountCellFunction,
        },
        {
          field: "yield",
          title: "Yield",
          filter: "numeric",
          width: "200px",
          cell: this.yieldCellFunction,
        },
        {
          field: "term",
          title: "Term (month)",
          filter: "numeric",
          width: "200px",
        },
        {
          field: "maturity",
          title: "Maturity",
          filter: "numeric",
          width: "200px",
        },
        {
          field: "date_added",
          title: "Added",
          filter: "numeric",
          width: "200px",
        },
      ],
    };
  },
  created: function () {
    const dataState = {
      skip: this.skip,
      take: this.take,
      sort: this.sort,
      group: this.group,
    };
    this.gridData = process(this.items, dataState);
  },
  methods: {
    yieldCellFunction: function (h, tdElement, props, listeners) {
      return h("td", {}, [this.formatYield(props.dataItem.yield)]);
    },
    amountCellFunction: function (h, tdElement, props, listeners) {
      return h("td", {}, [this.formatNumber(props.dataItem.amount)]);
    },
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    toggleSubmit() {
      this.visibleDialog = false;
      this.visibleSubmit = !this.visibleSubmit;
    },
    getData: function () {
      let dataState = {
        take: this.take,
        skip: this.skip,
        filter: this.filter,
        sort: this.sort,
      };

      this.gridData = process(this.items, dataState);
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      if (dataState.group) {
        dataState.group.map((group) => (group.aggregates = this.aggregates));
      }
      this.group = dataState.group;
      this.filter = dataState.filter;
      this.sort = dataState.sort;
      this.getData();
    },
    dataStateChange(event) {
      this.createAppState(event.data);
      this.gridData = process(this.items, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter,
        group: this.group,
      });
    },
    expandChange(event) {
      const isExpanded =
        event.dataItem.expanded === undefined
          ? event.dataItem.aggregates
          : event.dataItem.expanded;
      event.dataItem.expanded = !isExpanded;
    },
    pageChangeHandler: function (event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    columnReorder: function (options) {
      this.columns = options.columns;
    },
    onHeaderSelectionChange(event) {
      let checked = event.event.target.checked;
      if (this.items !== undefined) {
        this.items = this.items.map((item) => {
          return { ...item, selected: checked };
        });
      }
      this.gridData = process(this.items, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter,
        group: this.group,
      });
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
    onRowClick(event) {
      this.selectedID = event.dataItem.bond_id;
      if (this.selectedID !== undefined) {
        this.$router.push({ name: "detail", params: { id: this.selectedID } });
      }
    },
  },
};
</script>


